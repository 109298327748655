import React, { Component } from 'react';
import { fetchRequest } from 'helpers/fetchRequests';
import { Spin, Button } from 'antd';
import IssueForm from 'components/issueForm';
import { message } from 'antd';
import { capitalize } from 'helpers/strings';
import { withTranslation } from 'react-i18next';
import Subscriber from 'components/subscriber';
import OrganisationTree, { getChecked } from 'interface/organisationTree';
import 'styles.scss';
import { Alert } from 'antd';
import i18n from 'i18next';
import { BrowserRouter, Route, Redirect, Switch, Link } from 'react-router-dom';

class ScheduleListView extends Component {
  constructor(props: any) {
    super(props);
    this.state = { schedules: [] };
  }

  fetchReports() {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));
    const apiUrl = process.env.REACT_APP_API_ADDRESS || 'https://api.estatelogs.com/';
    const url = apiUrl + '3/schedules';
    const body = { task: this.state.tasks || [], role: 'technician' };

    let okCallback = (ret) => {
      return ret.json().then((json) => {
        this.setState({ schedules: json.data }, this.updateSubscriptions.bind(this));
      });
    };
    let errorCallback = (ret) => {
      message('error');
      this.setState({ loaded: true });
    };

    let badCallback = (ret) => {
      this.setState({ loaded: true });
      if (ret.status === 500) {
        message('internal server error');
      } else {
        return ret
          .json()
          .then((json) => {
            console.log('blah');
          })
          .catch(() => message('super error'));
      }
    };
    fetchRequest({ url, okCallback, badCallback, errorCallback, token: 'token', body });
  }

  fetchTree() {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    const apiUrl = process.env.REACT_APP_API_ADDRESS || 'https://api.estatelogs.com/';
    const url = apiUrl + '3/organisation';

    let okCallback = (ret) => {
      return ret.json().then((json) => {
        this.setState({ tree: json });
      });
    };
    let errorCallback = (_) => {
      this.setState({ loaded: true, error: t('could-not-connect-to-server') });
    };

    let badCallback = (ret) => {
      this.setState({ loaded: true });
      if (ret.status === 500) {
        this.setState({ error: t('internal-server-error') });
      } else if (ret.status === 404) {
        this.setState({ error: t('issue-with-id-not-found', { id: this.props.uuid }) });
      } else {
        return ret
          .json()
          .then((json) => {})
          .catch(() => this.setState({ error: t('error-and-error') }));
      }
    };
    let body = { loaded: ['Organisation', 'EstateGroup', 'Estate', 'TaskCategory', 'Task'] };
    fetchRequest({ url, okCallback, badCallback, errorCallback, token: 'token', body });
  }

  handleData(data) {
    this.fetchReports();
  }

  componentDidMount() {
    this.fetchTree();
  }

  handleOpen(connections) {
    console.log('number of connections: ', connections);
  }

  renderReport(schedule) {
    return (
      <div style={{ borderRadius: '1em', background: '#ee', margin: '8px', padding: '8px' }}>
        <div>{schedule.task.property.name}</div>
        <div>{schedule.task.name}</div>
        <div>{schedule.dueDatetime}</div>
        <Link to={'/reports/' + schedule.uuid} style={{ fontSize: '0.8em' }}>
          {schedule.uuid}
        </Link>
      </div>
    );
  }

  updateSubscriptions() {
    this.setState({
      subscribe: {
        schedule: {
          deleted: {
            property: this.state.estates || [],
            taskCategory: this.state.taskCategories || []
          },
          edited: {
            task: this.state.tasks || [],
            property: this.state.estates || [],
            taskCategory: this.state.taskCategories || []
          },
          created: {
            property: this.state.estates || [],
            taskCategory: this.state.taskCategories || []
          }
        }
      }
    });
  }

  handleTreeChange(tree) {
    let taskChecked = getChecked(tree, 'Task');
    let estateChecked = getChecked(tree, 'Estate');
    let taskCategoryChecked = getChecked(tree, 'TaskCategory');
    this.setState(
      { tree, estates: estateChecked, taskCategories: taskCategoryChecked, tasks: taskChecked },
      this.updateSubscriptions.bind(this)
    );
  }

  onClick(e) {
    this.fetchReports();
  }

  render() {
    if (!this.state.tree) {
      return <div>Loading tree</div>;
    }
    return (
      <div style={{ display: 'flex' }}>
        <div>
          <OrganisationTree
            token={this.props.authToken}
            leaf={'Task'}
            tree={this.state.tree}
            onChange={this.handleTreeChange.bind(this)}
          />
          <Subscriber
            url={process.env.REACT_APP_WEBSOCKET_ADDRESS}
            subscribe={this.state.subscribe}
            onMessage={this.handleData.bind(this)}
            onOpen={this.handleOpen.bind(this)}
            token='token'
          />
          <Button onClick={this.onClick.bind(this)}>Get schedules</Button>
        </div>
        <div style={{ padding: '1em' }}>
          {' '}
          {this.state.schedules.map((i) => this.renderReport(i))}
        </div>
      </div>
    );
  }
}

export default withTranslation('shareFrontend')(ScheduleListView);

import React from 'react';
import PropTypes from 'prop-types';

class Websocket extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.connections = 0; // Number of times we were disconnected minus one (if nonzero)
    this.attempts = 0; // Number of connection attempts while attempting a reconnect
  }

  /**
   * Sets up the websocket; called at component mount and disconnect
   */
  setupWebsocket() {
    this.websocket = new WebSocket(this.props.url);
    this.websocket.onopen = this.handleOpen.bind(this);
    this.websocket.onmessage = this.handleMessage.bind(this);
    this.websocket.onclose = this.handleClose.bind(this);
  }

  /**
   * Callback for websocket open
   */
  handleOpen() {
    this.subscribe();
    this.attempts = 0;
    this.connections++;
    if (this.props.onOpen) {
      this.props.onOpen(this.connections);
    }
  }

  /**
   * Handler for message reception
   * @param evt - The message reception eventlistener object
   */
  handleMessage(evt) {
    this.props.onMessage(JSON.parse(evt.data));
  }

  /**
   * Handler for when when a websocket is closed, typically by the other end; starts a periodic
   * reconnection attempt
   */
  handleClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.timeout = setTimeout(() => {
      ++this.attempts;
      this.setupWebsocket();

      // Exponential backoff, try again in 100ms, 200ms, 400ms, 800ms, 1000ms, 1000ms, ...
    }, Math.min(1000, 100 * Math.pow(2, this.attempts)));
  }

  /**
   * Sends a websocket message.
   * @param s - The json to send
   */
  send(s) {
    if (this.websocket.readyState === WebSocket.OPEN) {
      clearTimeout(this.resendTimer);
      this.resendTimer = undefined;
      this.websocket.send(s);
    } else {
      this.resendTimer = setTimeout(this.send.bind(this), 250);
    }
  }

  /**
   * Checks if the subscription info has changed; in that case, sends another subscribe message
   * @param prevProps - The old props
   */
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.subscribe) != JSON.stringify(this.props.subscribe)
      || prevProps.token != this.props.token
    ) {
      this.subscribe();
    }
  }

  /**
   * Sends a subscription message containing the channels to subscribe to and the authentication
   * token that associates this websocket with the user
   */
  subscribe() {
    if (this.props.subscribe || this.props.token) {
      this.send(
        JSON.stringify({
          subscribe: this.props.subscribe,
          token: this.props.token
        })
      );
    }
  }

  /**
   * Opens the socket
   */
  componentDidMount() {
    this.setupWebsocket();
  }

  /**
   * Closes the socket
   */
  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.resendTimer);
    this.websocket.onclose = null;
    this.websocket.close();
  }

  /**
   * We don't actually render a DOM element
   */
  render() {
    return null;
  }
}

export default Websocket;

import React, { Component, Suspense } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { fetchRequest } from 'helpers/fetchRequests.js';
import sv_SE from 'antd/lib/locale-provider/sv_SE';
import fi_FI from 'antd/lib/locale-provider/fi_FI';
import en_US from 'antd/lib/locale-provider/en_US';
import { LocaleProvider } from 'antd'; // For various antd widgets
import { namespaces } from './namespaces';
import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/fi';

const ourLocaleToAntdLocale = {
  // For antd's LocaleProvider
  sv: sv_SE,
  fi: fi_FI,
  en: en_US,
  'en-US': en_US
};

const ourLocaleToMomentLocale = {}; // So far our locale names are the same as moment's

class LanguageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Sends a fetch request to update the language dictionary.
   */
  loadLocales() {
    let body = { namespaces };
    let okCallback = (ret) =>
      ret.json().then((json) => {
        // Json structure is { sv: { namespace1: { key: value, ... }, namespace2: ... }, en: ... }
        for (let [language, namespaces] of Object.entries(json.data || {})) {
          for (let [namespace, dict] of Object.entries(namespaces)) {
            i18n.addResourceBundle(language, namespace, dict, true, true);
          }
        }
        // Seems like you have to call changeLanguage to trigger a rerender of stuff wrapped in
        // withTranslation
        i18n.changeLanguage(i18n.language || 'en');
      });
    fetchRequest({ url: this.props.dictUrl, okCallback, body });
  }

  /**
   * This initializes i18next and sends a fetch request.
   */
  componentDidMount() {
    moment.locale(ourLocaleToMomentLocale[this.props.locale] || this.props.locale || 'en');
    let dict = {};
    try {
      dict = require('./dictionary.js').default;
    } catch (err) {
      // File doesn't exist, no bundled dictionary
      dict = undefined;
    }
    i18n
      .use(initReactI18next)
      .init({
        lng: this.props.locale,
        fallbackLng: 'en',
        resources: dict,
        returnEmptyString: false
      })
      .then(() => {
        this.loadLocales(); // Once i18next finishes initializing, fetch the updated dictionary
        this.setState({ i18nLoaded: true });
      });
  }

  /**
   * If the locale properties change, we use i18n to changeLanguage as well.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.locale != this.props.locale) {
      i18n.changeLanguage(this.props.locale);
      moment.locale(ourLocaleToMomentLocale[this.props.locale] || this.props.locale || 'en');
    }
  }

  render() {
    if (!this.state.i18nLoaded) {
      // Delay rendering until the i18n init has finished (not strictly necessary, but might
      // prevent a slight flicker in the beginning as it re-renders (?)
      return null;
    }

    return (
      // LocaleProvider is for antd components like DatePicker
      <LocaleProvider
        locale={ourLocaleToAntdLocale[this.props.locale] || this.props.locale || 'en'}
      >
        {this.props.children}
      </LocaleProvider>
    );
  }
}

export default LanguageLoader;

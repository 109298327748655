import { notification } from 'antd';

/**
 * Constructs a generic fetch request and returns its returned promise.
 * @param args - The argument object, containing the following fields:
 *        url - The full url of the request
 *        token - The authorization token
 *        method - 'POST', 'GET', 'DELETE', 'PUT' or 'PATCH'. Defaults to 'GET'.
 *        accept - The accept part of the header. Defaults to 'application/json'.
 *        okCallback - Function taking a promise fulfillment response object and returning a
 *                     promise, called when response.ok is true
 *        badCallback - Same as okCallback but for when response.ok is false
 *        errorCallback - Called when the fetch request failed entirely, as for nonexistant ips etc
 *        body - The information sent to the server
 *
 *        All arguments are optional except the url.
 */
export function fetchRequest(args) {
  let {
    url,
    token,
    method = 'GET',
    accept = 'application/json',
    okCallback,
    badCallback,
    errorCallback,
    body = {}
  } = args;

  if (!url) {
    throw Error('url is required');
  }

  if (!['GET', 'POST', 'PATCH', 'PUT', 'DELETE'].includes(method)) {
    throw Error('Bad method');
  }

  if (method == 'GET' && Object.entries(body).length) {
    // Turn the body into a url query?key=val string
    url
      += '?'
      + Object.entries(body)
        .map(([key, val]) =>
          (Array.isArray(val) ? val : [val]).map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
        )
        .join('&');
  }

  let promise = fetch(url, {
    method,
    body: method != 'GET' ? JSON.stringify(body) : undefined,
    withCredentials: true,
    credentials: 'include',
    headers: {
      Authorization: token ? 'Token ' + token : undefined,
      'Content-Type': 'application/json',
      'Accept-Language':
        window.navigator.userLanguage || window.navigator.language || navigator.language,
      Accept: accept
    }
  }).then((ret) => {
    if (ret.ok) {
      if (okCallback) {
        return okCallback(ret);
      }
    } else {
      if (badCallback) {
        return badCallback(ret);
      }
    }
  });
  if (errorCallback) {
    promise = promise.catch(function(ret) {
      if (errorCallback) {
        return errorCallback(ret);
      }
    });
  }
  return promise;
}

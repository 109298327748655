export default { en: 
   { shareFrontend: 
      { accepted: 'accepted',
        cancelled: 'cancelled',
        comments: 'comments',
        completed: 'completed',
        'completed-on': 'completed on',
        contact: 'contact',
        'could-not-connect': 'could not connect to server',
        created: 'created',
        description: 'description',
        'entry-by-main-key-authorised': 'entry by main key authorized',
        'error-and-error': 'error and error in error message',
        'image-of-the-issue': 'image of the issue',
        'internal-server-error': 'internal server error',
        'issue-with-id-not-found': 'service request with id {{id}} not found',
        'loading-issue': 'loading service request',
        location: 'location',
        'materials-used-to-complete': 'materials used to complete',
        minute: 'minute',
        minute_plural: 'minutes',
        name: 'name',
        no: 'no',
        notes: 'notes',
        pending: 'pending',
        'pets-information': 'pets information',
        'pets-present': 'pets present',
        reference: 'reference',
        rejected: 'rejected',
        status: 'status',
        submitter: 'submitter',
        task: 'task',
        'technician-notes': 'technician notes',
        'time-used-to-complete': 'time taken to complete',
        type: 'type',
        unassigned: 'unassigned',
        uncompleted: 'uncompleted',
        unknown: 'unknown',
        'visit-information': 'visit information',
        'visit-instructions': 'visit instructions',
        yes: 'yes' } },
  sv: 
   { shareFrontend: 
      { accepted: 'mottagen',
        cancelled: 'annullerad',
        comments: 'kommentarer',
        completed: 'utförd',
        'completed-on': 'skapad den',
        contact: 'kontakt',
        'could-not-connect': 'kunde inte ansluta till servern',
        created: 'skapad',
        description: 'beskrivning',
        'entry-by-main-key-authorised': 'godkännande av användning av huvudnyckel',
        'error-and-error': 'fel och fel i felmeddelande',
        'image-of-the-issue': 'bild på felet',
        'internal-server-error': 'internt serverfel',
        'issue-with-id-not-found': 'felanmälan med id {{id}} inte funnen',
        'loading-issue': 'laddar felanmälan',
        location: 'plats',
        'materials-used-to-complete': 'materialanvändning',
        minute: 'minut',
        minute_plural: 'minuter',
        name: 'namn',
        no: 'nej',
        notes: 'noteringar',
        pending: 'tillfrågad',
        'pets-information': 'information om husdjur',
        'pets-present': 'husdjur närvarande',
        reference: 'referens',
        rejected: 'avvisad',
        status: 'status',
        submitter: 'inskickad av',
        task: 'tillsynsuppgift',
        'technician-notes': 'noteringar från tekniker',
        'time-used-to-complete': 'tidsåtgång',
        type: 'typ',
        unassigned: 'otilldelad',
        uncompleted: 'ej utförd',
        unknown: 'okänt',
        'visit-information': 'besöksinformation',
        'visit-instructions': 'besöksinstruktioner',
        yes: 'ja' } } }
import 'helpers/polyfills';
import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import PrimaryRouter from './routers/primaryRouter';
import LanguageLoader from 'components/languageLoader';

// initial path is used to capture the initially requested path so that we can forward the
// user there after they've been authenticated
const initialPath = window.location.pathname;
const dictUrl
  = (process.env.REACT_APP_API_ADDRESS || 'https://api.estatelogs.com/') + '3/dictionaries/';

ReactDOM.render(
  <LanguageLoader
    locale={window.navigator.userLanguage || window.navigator.language || navigator.language}
    dictUrl={dictUrl}
  >
    <PrimaryRouter initialPath={initialPath} />
  </LanguageLoader>,
  document.getElementById('root')
);

import React, { Component } from 'react';
import { fetchRequest } from 'helpers/fetchRequests';
import { Spin, Button } from 'antd';
import ScheduleForm from 'components/scheduleForm/index.js';
import { message } from 'antd';
import { capitalize } from 'helpers/strings';
import { withTranslation } from 'react-i18next';
import Subscriber from 'components/subscriber';
import 'styles.scss';
import { Alert } from 'antd';
import i18n from 'i18next';

class ScheduleView extends Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  fetchSchedule() {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    const apiUrl = process.env.REACT_APP_API_ADDRESS || 'https://api.estatelogs.com/';
    const url = apiUrl + '3/schedules/' + this.props.uuid;

    let okCallback = (ret) => {
      return ret.json().then((json) => {
        this.setState({ loaded: true, schedule: json.data });
      });
    };
    let errorCallback = (_) => {
      this.setState({ loaded: true, error: t('could-not-connect-to-server') });
    };

    let badCallback = (ret) => {
      this.setState({ loaded: true });
      if (ret.status === 500) {
        this.setState({ error: t('internal-server-error') });
      } else if (ret.status === 404) {
        this.setState({ error: t('schedule-with-id-not-found', { id: this.props.uuid }) });
      } else {
        return ret
          .json()
          .then((json) => {
            this.setState({ error: json.message });
          })
          .catch(() => this.setState({ error: t('error-and-error') }));
      }
    };
    fetchRequest({ url, okCallback, badCallback, errorCallback, token: 'token' });
  }

  handleData(json) {
    if (json.type == 'schedule.edited') {
      this.setState({ schedule: { ...this.schedule, ...json.data } });
    } else {
      this.setState({ loaded: false, schedule: false });
    }
  }

  render() {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    if (!this.state.loaded) {
      this.fetchSchedule(); // If not loaded, need an update

      if (!this.state.schedule) {
        // This is the first time we open the page, show the loading indicator
        return (
          <div className='loading-wrapper'>
            <Spin tip={`${T('loading-schedule')} ` + this.props.uuid} />
          </div>
        );
      }
      return (
        <div>
          <ScheduleForm schedule={this.state.schedule} />
          <Subscriber
            url={process.env.REACT_APP_WEBSOCKET_ADDRESS}
            token='token'
            onMessage={this.handleData.bind(this)}
            subscribe={{
              schedule: {
                edited: { property: this.state.schedule.task.property.uuid },
                deleted: { task: this.state.schedule.uuid }
              }
            }}
          />
          {this.state.schedule.report ? (
            <div>
              <Subscriber
                subscribe={{
                  report: {
                    edited: { report: this.state.schedule.report.uuid },
                    deleted: { task: this.state.schedule.uuid }
                  }
                }}
              />
              {this.state.reports.created.datetime}
            </div>
          ) : null}
        </div>
      );
    } else {
      // state.loaded is true; Something has been loaded/updated
      if (this.state.schedule) {
        console.log(this.state.schedule);
        return (
          <div>
            <ScheduleForm schedule={this.state.schedule} />
            <Subscriber
              url={process.env.REACT_APP_WEBSOCKET_ADDRESS}
              token='token'
              onMessage={this.handleData.bind(this)}
              subscribe={{
                schedule: {
                  edited: { task: this.state.schedule.task.uuid },
                  deleted: { task: this.state.schedule.task.uuid }
                },
                report: {
                  created: { schedule: this.state.schedule.uuid },
                  edited: { schedule: this.state.schedule.uuid }
                }
              }}
            />
          </div>
        );
      } else {
        // Schedule loaded unsuccessfully
        return (
          <div className='error-wrapper'>
            <Alert
              message={T('could-not-load-service-request') + ': ' + this.state.error}
              type='error'
            />
          </div>
        );
      }
    }
  }
}

export default withTranslation('shareFrontend')(ScheduleView);

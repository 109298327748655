import React, { Component } from 'react';
import { fetchRequest } from 'helpers/fetchRequests';
import { Spin, Button } from 'antd';
import IssueForm from 'components/issueForm';
import { message } from 'antd';
import { capitalize } from 'helpers/strings';
import { withTranslation } from 'react-i18next';
import Subscriber from 'components/subscriber';
import 'styles.scss';
import { Alert } from 'antd';
import i18n from 'i18next';

class IssueView extends Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  fetchIssue() {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    const apiUrl = process.env.REACT_APP_API_ADDRESS || 'https://api.estatelogs.com/';
    const url = apiUrl + '3/issues/' + this.props.uuid;

    let okCallback = (ret) => {
      return ret.json().then((json) => {
        this.setState({ loaded: true, issue: json.data });
      });
    };
    let errorCallback = (_) => {
      this.setState({ loaded: true, error: t('could-not-connect-to-server') });
    };

    let badCallback = (ret) => {
      this.setState({ loaded: true });
      if (ret.status === 500) {
        this.setState({ error: t('internal-server-error') });
      } else if (ret.status === 404) {
        this.setState({ error: t('issue-with-id-not-found', { id: this.props.uuid }) });
      } else {
        return ret
          .json()
          .then((json) => {
            this.setState({ error: json.message });
          })
          .catch(() => this.setState({ error: t('error-and-error') }));
      }
    };
    fetchRequest({ url, okCallback, badCallback, errorCallback });
  }

  handleData(json) {
    console.log('received', json);
    if (json.type == 'issue.edited') {
      this.setState({ issue: { ...this.issue, ...json.data } });
    } else {
      this.setState({ loaded: false, issue: false });
    }
  }

  handleOpen(disc) {
    console.log('connections: ', disc);
  }

  render() {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    if (!this.state.loaded) {
      this.fetchIssue(); // If not loaded, need an update

      if (!this.state.issue) {
        // This is the first time we open the page, show the loading indicator
        return (
          <div className='loading-wrapper'>
            <Spin tip={`${T('loading-issue')} ` + this.props.uuid} />
          </div>
        );
      }
      return (
        <div>
          <IssueForm issue={this.state.issue} />
          <Subscriber
            url={process.env.REACT_APP_WEBSOCKET_ADDRESS}
            subscribe={{
              deleted: {},
              issue: {
                edited: { issue: this.state.issue.uuid },
                deleted: { issue: this.state.issue.uuid }
              },
              issueComment: {
                deleted: { issue: this.state.issue.uuid },
                created: { issue: this.state.issue.uuid }
              }
            }}
            onMessage={this.handleData.bind(this)}
          />
        </div>
      );
    } else {
      // state.loaded is true; Something has been loaded/updated
      if (this.state.issue) {
        return (
          <div>
            <IssueForm issue={this.state.issue} />
            <Subscriber
              url={process.env.REACT_APP_WEBSOCKET_ADDRESS}
              onMessage={this.handleData.bind(this)}
              onOpen={this.handleOpen.bind(this)}
              subscribe={{
                issue: {
                  edited: { issue: this.state.issue.uuid },
                  deleted: { issue: this.state.issue.uuid }
                },
                issueComment: {
                  deleted: { issue: this.state.issue.uuid },
                  created: { issue: this.state.issue.uuid }
                }
              }}
            />
          </div>
        );
      } else {
        // Issue loaded unsuccessfully
        return (
          <div className='error-wrapper'>
            <Alert
              message={T('could-not-load-service-request') + ': ' + this.state.error}
              type='error'
            />
          </div>
        );
      }
    }
  }
}

export default withTranslation('shareFrontend')(IssueView);

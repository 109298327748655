export function removeDashes(uuid) {
  return uuid.replace(/-/g, '');
}

export function limitStringLength(str, length = 100, ending = '...') {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}
export function ensureTrailingSlash(string) {
  if (string.substr(-1) !== '/') {
    string += '/';
  }
  return string;
}

export function removeInitialSlash(string) {
  return string.length && string[0] === '/' ? string.slice(1) : string;
}

/**
 * Returns the given string with the first letter capitalized
 * @param str - The string to capitalize
 */
export function capitalize(str) {
  return !str.length ? '' : str[0].toUpperCase() + str.substr(1);
}

import React, { Component } from 'react';
import { fetchRequest } from 'helpers/fetchRequests';
import { Spin, Button } from 'antd';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import i18n from 'i18next';

import { capitalize } from 'helpers/strings';
import moment from 'moment';

class IssueForm extends Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  renderComment(comment) {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));
    return (
      <div className='comment-wrapper'>
        <table cellPadding='0' cellSpacing='0'>
          <tr className='comment-header'>
            <td>{comment.name || (comment.user ? comment.user.userName : T('unknown-user'))}</td>
            <td>{moment(comment.createdDatetime).format('YYYY-MM-DD')}</td>
          </tr>
          <tr>
            <td colSpan={2} className='comment-body'>
              {comment.body}
            </td>
          </tr>
        </table>
      </div>
    );
  }

  render() {
    // NOTE: keep in mind some fields might not show up due to
    //       the issue serializer being the public version

    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    const { issue } = this.props;
    console.log(issue);
    return (
      <div className='wrapper'>
        <div style={{ textAlign: 'center', padding: '20px' }} className='no-print'>
          <a href='javascript:window.print()'>Print page</a>
        </div>
        <div>
          <div className='invoice-box'>
            <table cellPadding='0' cellSpacing='0'>
              <tr className='top'>
                <td colSpan='2'>
                  <table>
                    <tbody>
                      <tr>
                        <td className='title'>
                          <img
                            src='http://cdn.estatelogs.com/sharing/images/estate-logs-logo.png'
                            alt='Estate Logs Logo'
                            style={{ width: '180px' }}
                          />
                        </td>
                        <td>
                          {`${T('reference')}: ${issue.referenceCode}`}
                          <br />
                          {`${T('created')}: ${moment(issue.createdDatetime).format('YYYY-MM-DD')}`}
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr className='information'>
                <td colSpan='2'>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ paddingBottom: '20px' }}>
                          <strong>{issue.property.name}</strong>
                          <br />
                          {(issue.property.streetName || '')
                            + ' '
                            + (issue.property.streetNumber || '')}
                          <br />
                          {(issue.property.areaCode || '') + ' ' + (issue.property.region || '')}
                          <br />
                          {issue.property.country}
                        </td>

                        <td style={{ paddingBottom: '20px' }}>
                          <strong>{T('status')}</strong>
                          <br />
                          {T(issue.status)}
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>

            {issue.status == 'completed' && issue.completedDatetime ? (
              <div
                style={{
                  marginBottom: '15px',
                  border: '1px solid #c7ef9a',
                  background: '#f7ffee',
                  padding: '10px'
                }}>
                <div>
                  <strong>{T('completed-on')}: </strong>
                </div>
                <div>{moment(issue.completedDatetime).format('YYYY-MM-DD')}</div>
                {issue.issueResolvedComment ? (
                  <div>
                    <div>
                      <strong>{T('technician-notes')}: </strong>
                    </div>
                    <div style={{ marginBottom: '15px' }}>{issue.issueResolvedComment}</div>
                  </div>
                ) : null}
                {issue.materialsUsedToComplete ? (
                  <div>
                    <div>
                      <strong>{T('materials-used-to-complete')}: </strong>
                    </div>
                    <div style={{ marginBottom: '15px' }}>{issue.materialsUsedToComplete}</div>
                  </div>
                ) : null}

                {issue.minutesTakenToComplete ? (
                  <div>
                    <div>
                      <strong>{T('time-taken-to-complete')}: </strong>
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                      {issue.minutesTakenToComplete}{' '}
                      {T('minute', { count: issue.minutesTakenToComplete })}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <table cellPadding='0' cellSpacing='0'>
              {issue.task ? (
                <tr className='heading'>
                  <td>{T('task')}</td>
                  <td />
                </tr>
              ) : null}
              {issue.task ? (
                <tr className='details'>
                  <td>{issue.task.name}</td>
                </tr>
              ) : null}
              <tr className='heading'>
                <td>{T('description')}</td>
                <td />
              </tr>

              <tr className='details'>
                <td>
                  <div>{issue.description}</div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      alignContent: 'flex-start',
                      marginTop: '15px'
                    }}>
                    {issue.files.map((f, i) => (
                      <a href={f.location} key={i}>
                        <img
                          src={f.preview}
                          alt={t('image-of-the-issue')}
                          style={{ width: '90px', height: '90 px', marginRight: '10px' }}
                        />
                      </a>
                    ))}
                  </div>
                </td>
              </tr>
              {issue.location ? (
                <tr>
                  <tr className='heading'>
                    <td>{T('location')}</td>
                    <td>{T('type')}</td>
                  </tr>

                  <tr className='details'>
                    <td>{issue.location}</td>
                    <td>{issue.locationType}</td>
                  </tr>
                </tr>
              ) : null}
              <tr className='heading'>
                <td>{T('visit-information')}</td>

                <td />
              </tr>

              <tr className='item'>
                <td>{T('entry-by-main-key-authorised')}</td>

                <td>{T(issue.entryByKeyAllowed)}</td>
              </tr>
              {issue.technicianVisitInstructions ? (
                <tr className='item'>
                  <td>{T('visit-instructions')}</td>
                  <td>{issue.technicianVisitInstructions}</td>
                </tr>
              ) : null}

              <tr className='item'>
                <td>{T('pets-present')}</td>
                <td>{T(issue.petsPresent)}</td>
              </tr>
              {issue.petsInformation ? (
                <tr className='item last'>
                  <td style={{ paddingBottom: '20px' }}>{T('pets-information')}</td>
                  <td>{issue.petsInformation}</td>
                </tr>
              ) : null}

              {issue.notes ? (
                <tr className='heading'>
                  <td>{T('notes')}</td>
                  <td />
                </tr>
              ) : null}
              {issue.notes ? (
                <tr className='item'>
                  <td style={{ paddingBottom: '20px' }}>{issue.notes}</td>
                </tr>
              ) : null}

              <tr className='heading'>
                <td>{T('submitter')}</td>

                <td />
              </tr>

              <tr className='item'>
                <td>{T('name')}</td>

                <td>
                  {issue.submittedBy.name && issue.submittedBy.name.length
                    ? issue.submittedBy.name
                    : issue.createdByUser.userName}
                </td>
              </tr>
              <tr className='item last'>
                <td>{T('contact')}</td>

                <td>
                  {issue.submittedBy.contactInformation
                  && issue.submittedBy.contactInformation.length
                    ? issue.submittedBy.contactInformation
                    : issue.createdByUser.email || issue.createdByUser.telephone || 'None'}
                </td>
              </tr>
              <tr className='heading'>
                <td>{T('comments')}</td>

                <td />
              </tr>

              <tr>
                <td colSpan={2}>
                  {(issue.comments || []).map((c, i) => this.renderComment(c, i))}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('shareFrontend')(IssueForm);

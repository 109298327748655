// I wanted to export all the other polyfill imports in here as well, like
// export * from 'core-js/es6' but couldn't get it to work

import 'raf/polyfill';
import 'core-js/es6'; // polyfil es6 features
import 'whatwg-fetch';

Object.entries = Object.entries || ((obj) => {
    let keys = Object.keys(obj);
    return keys.map((k) => [k, obj[k]]);
  }); // prettier-ignore

Object.values = Object.values || ((obj) => {
    let keys = Object.keys(obj);
    return keys.map((k) => obj[k]);
  }); // prettier-ignore

import React, { Component } from 'react';
import { fetchRequest } from 'helpers/fetchRequests';
import { Spin, Button } from 'antd';
import IssueForm from 'components/issueForm';
import { message } from 'antd';
import { capitalize } from 'helpers/strings';
import { withTranslation } from 'react-i18next';
import Subscriber from 'components/subscriber';
import OrganisationTree, { getChecked } from 'interface/organisationTree';
import 'styles.scss';
import { Alert } from 'antd';
import i18n from 'i18next';
import { BrowserRouter, Route, Redirect, Switch, Link } from 'react-router-dom';

class IssueListView extends Component {
  constructor(props: any) {
    super(props);
    this.state = { issues: [] };
  }

  fetchIssues() {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));
    const apiUrl = process.env.REACT_APP_API_ADDRESS || 'https://api.estatelogs.com/';
    const url = apiUrl + '3/issues';
    const body = { property: this.state.estates || [] };

    let okCallback = (ret) => {
      return ret.json().then((json) => {
        this.setState({ issues: json.data }, this.updateSubscriptions.bind(this));
      });
    };
    let errorCallback = (_) => {
      this.setState({ loaded: true, error: t('could-not-connect-to-server') });
    };

    let badCallback = (ret) => {
      this.setState({ loaded: true });
      if (ret.status === 500) {
        this.setState({ error: t('internal-server-error') });
      } else if (ret.status === 404) {
        this.setState({ error: t('issue-with-id-not-found', { id: this.props.uuid }) });
      } else {
        console.log(ret);
        return ret
          .json()
          .then((json) => {
            console.log('blah');
          })
          .catch(() => this.setState({ error: t('error-and-error') }));
      }
    };
    fetchRequest({ url, okCallback, badCallback, errorCallback, token: 'token', body });
  }

  fetchTree() {
    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    const apiUrl = process.env.REACT_APP_API_ADDRESS || 'https://api.estatelogs.com/';
    const url = apiUrl + '3/organisation';
    let okCallback = (ret) => {
      return ret.json().then((json) => {
        this.setState({ tree: json });
      }, this.updateSubscriptions.bind(this));
    };
    let errorCallback = (_) => {
      this.setState({ loaded: true, error: t('could-not-connect-to-server') });
    };

    let badCallback = (ret) => {
      this.setState({ loaded: true });
      if (ret.status === 500) {
        this.setState({ error: t('internal-server-error') });
      } else if (ret.status === 404) {
        this.setState({ error: t('issue-with-id-not-found', { id: this.props.uuid }) });
      } else {
        console.log(ret);
        return ret
          .json()
          .then((json) => {
            console.log('blah');
          })
          .catch(() => this.setState({ error: t('error-and-error') }));
      }
    };
    fetchRequest({ url, okCallback, badCallback, errorCallback, token: 'token' });
  }

  handleData(data) {
    console.log('data from websocket ', data);
    //if (data.startsWith('issue edited')) {
    //  this.fetchIssues();
    //}
    this.fetchIssues();
  }

  componentDidMount() {
    this.fetchTree();
  }

  renderIssue(issue) {
    return (
      <div style={{ borderRadius: '1em', background: '#ee', margin: '8px', padding: '8px' }}>
        <div>{issue.property.name}</div>
        <div>{issue.description}</div>
        <Link to={'/issues/' + issue.uuid} style={{ fontSize: '0.8em' }}>
          {issue.uuid}
        </Link>
      </div>
    );
  }

  updateSubscriptions() {
    this.setState({
      subscribe: {
        issue: {
          deleted: { property: this.state.estates || [] },
          created: { property: this.state.estates || [] },
          edited: { issue: this.state.issues.map((i) => i.uuid) || [] }
        }
      }
    });
  }

  handleTreeChange(tree) {
    let checked = getChecked(tree, 'Estate');
    this.setState({ tree, estates: checked }, this.updateSubscriptions.bind(this));
  }

  onClick(e) {
    this.fetchIssues();
  }

  render() {
    if (!this.state.tree) {
      return <div>Loading tree</div>;
    }
    return (
      <div style={{ display: 'flex' }}>
        <div>
          <OrganisationTree
            token={this.props.authToken}
            leaf={'Estate'}
            tree={this.state.tree}
            onChange={this.handleTreeChange.bind(this)}
          />
          <Subscriber
            url={process.env.REACT_APP_WEBSOCKET_ADDRESS}
            token={process.env.REACT_APP_TOKEN}
            subscribe={this.state.subscribe}
            onMessage={this.handleData.bind(this)}
          />
          <Button onClick={this.onClick.bind(this)}>Get issues</Button>
        </div>
        <div style={{ padding: '1em' }}> {this.state.issues.map((i) => this.renderIssue(i))}</div>
      </div>
    );
  }
}

export default withTranslation('shareFrontend')(IssueListView);

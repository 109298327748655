import React, { Component } from 'react';
import { fetchRequest } from 'helpers/fetchRequests';
import { Spin, Button } from 'antd';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

import { capitalize } from 'helpers/strings';
import moment from 'moment';

class ScheduleForm extends Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    // NOTE: keep in mind some fields might not show up due to
    //       the issue serializer being the public version

    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    const { issue } = this.props;
    console.log(issue);
    return <div className='wrapper'>{this.props.schedule.dueDatetime}</div>;
  }
}

export default withTranslation('shareFrontend')(ScheduleForm);

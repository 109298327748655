// @flow

import React from 'react';
import { BrowserRouter, Route, Redirect, Switch, Link } from 'react-router-dom';
import IssueView from 'IssueView';
import ScheduleView from 'ScheduleView';
import IssueListView from 'IssueListView';
import ScheduleListView from 'ScheduleListView';
import i18n from 'i18next';

class Routes extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BrowserRouter>
        <Link style={{ margin: '1em' }} to='/issues'>
          Issues
        </Link>
        <Link to='/schedules'>Schedules</Link>
        <Switch>
          <Route
            path='/issues/:uuid'
            match={this.props.computedMatch}
            render={(i) => <IssueView uuid={i.match.params.uuid} />}
          />
          <Route
            path='/schedules/:uuid'
            match={this.props.computedMatch}
            render={(i) => <ScheduleView uuid={i.match.params.uuid} />}
          />
          <Route path='/issues' render={(i) => <IssueListView />} />
          <Route path='/schedules' render={(i) => <ScheduleListView />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routes;

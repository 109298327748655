// @flow
import { Icon, Tree as AntTree } from 'antd';
import React, { Component } from 'react';
import { fetchRequest } from 'helpers/fetchRequests';
import './styles.scss';

import { withTranslation } from 'react-i18next';
import { capitalize } from 'helpers/strings';

const AntTreeNode = AntTree.TreeNode;

/**
 * Initializes the "loaded" property of the tree that keep track of whether the children
 * have been fetched from the server
 * @param nodes - Roots of the tree
 * @param leaf - The type of the leaf node
 */
function initLoaded(nodes, leaf) {
  for (let node of nodes) {
    if (!(node.type != leaf && !node.children.length)) {
      node.loaded = true;
    }
    if (node.children) {
      initLoaded(node.children, leaf);
    }
  }
}

/**
 * Initializes the tree from a pruned json tree and user settings
 * @param jsonTree - The pruned tree
 * @param settingsTree - The settings tree
 * @param leaf - The type of the leaf, like 'Task'
 */
export function initFromJson(jsonTree, settingsTree, leaf) {
  let tree = checkFromPruned(jsonTree, settingsTree);
  initLoaded(tree, leaf);
  return tree;
}

/**
 * Copies an entire tree but removes id's
 * @param node - The tree to copy
 */
function copyTree(node: any, removeKey: boolean): any {
  // any is workaround for spread bug
  let { key, ...nodeNoKey } = node; // eslint-disable-line
  return {
    ...nodeNoKey,
    children: node.children.map((n) => copyTree(n, removeKey)),
    key: removeKey ? undefined : node.key
  };
}

/**
 * Copies an entire tree and marks it checked
 * @param node - The tree to copy
 * @param checked - Whether that entire tree is checked
 */
function copyChecked(node, checked) {
  return {
    ...node,
    children: node.children.map((n) => copyChecked(n, checked)),
    checked: checked
  };
}

/**
 * Maps an organisation tree into a pruned tree, which is a tree without "checked" variables,
 * but where each fully checked node has its children pruned away.
 * @param nodes - The roots of the tree
 */
export function prunedFromChecked(nodes) {
  let newNodes = [];
  for (let node of nodes) {
    if (node.checked) {
      // This node is checked, return a childless node
      newNodes.push({ uuid: node.uuid, type: node.type, children: [], name: node.name });
    } else {
      let prunedKids = prunedFromChecked(node.children);
      if (prunedKids.length !== 0) {
        // This node is not checked, but has descendants that are: recurse
        newNodes.push({ uuid: node.uuid, type: node.type, children: prunedKids, name: node.name });
      }
    }
  }
  return newNodes;
}

/**
 * Fills the checked variables of a tree given a matching pruned tree. A pruned tree
 * is a tree where every leaf (without children) corresponds to a node in the real tree where
 * everything below is checked.
 * @param fullNodes - The roots of the entire tree
 * @param prunedNodes - The roots of the pruned tree
 */
function checkFromPruned(fullNodes, prunedNodes) {
  let roots = [];

  let checkedMap = Object.assign({}, ...prunedNodes.filter((n) => n).map((n) => ({ [n.uuid]: n })));

  for (let node of fullNodes) {
    if (!checkedMap[node.uuid]) {
      // Node isn't part of the settings; not checked
      roots.push(copyChecked(node, false));
    } else if (!checkedMap[node.uuid].children.length) {
      // If settings node is a leaf node, get everything in the real tree
      roots.push(copyChecked(node, true));
    } else {
      // Node part of settings and not a leaf node, there is more stuff below
      roots.push({
        ...node,
        children: checkFromPruned(node.children, checkedMap[node.uuid].children)
      });
    }
  }
  return roots;
}

/**
 * Returns an array of the uuids of the checked nodes of the given type
 * @param tree - The roots of the tree to search through
 * @param type - The type, like "Estate"
 */
export function getChecked(tree, type) {
  return tree
    .filter((n) => n.type === type && n.checked)
    .map((n) => n.uuid)
    .concat(...tree.map((n) => getChecked(n.children, type)));
}

/**
 * A user interface element that represents the hierarchical structure of an organisation, with
 * multiple root elements (a tree)
 * @prop tree - A list of nodes that constitute the roots of the organisation trees
 * @prop onCheck - A callback function that takes the current state of the tree as argument
 */
class OrganisationTree extends Component {
  keyTree;

  /**
   * Constructor
   *
   * @param props - React properties
   */
  constructor(props) {
    super(props);
  }

  /**
   * Sets the 'checked' property of the nodes of the given tree given a set of keys
   * @param node - The root of the tree
   * @param keys - The set of keys of nodes that will be checked
   */
  setChecked(node, keys) {
    // TODO: these can be put inside the mother function
    node.checked = !keys || keys.has(node.key);
    for (let child of node.children) {
      this.setChecked(child, node.checked ? null : keys);
    }
  }

  /**
   * Sets the 'expanded' property of the nodes of the given tree given a set of keys
   * @param node - The root of the tree
   * @param keys - The set of keys of nodes that will be set to expanded
   */
  setExpanded(node, keys) {
    node.expanded = keys.has(node.key);
    for (let child of node.children) {
      this.setExpanded(
        child,
        !node.expanded && !this.props.keepChildrenExpanded ? new Set() : keys
      );
    }
  }

  /**
   * Sets the 'loaded' property of the nodes of the given tree given a set of keys
   * @param node - The root of the tree
   * @param keys - The set of keys of nodes that will be set to loaded
   */
  setLoaded(node, keys) {
    node.loaded = keys.has(node.key);
    for (let child of node.children) {
      this.setLoaded(child, keys);
    }
  }

  /**
   * Callback from Ant Tree when a node has been clicked
   * @param checkedKeys - The keys that are checked in the tree
   */
  onCheck(checkedKeys) {
    checkedKeys = checkedKeys.map((i) => parseInt(i));
    for (let root of this.keyTree) {
      this.setChecked(root, new Set(checkedKeys));
    }
    this.props.onChange(this.keyTree.map((t) => copyTree(t, false)), true);
  }

  /**
   * Callback from Ant Tree when a node has been expanded
   * @param expandedKeys - The keys of the tree that are expanded
   */
  onExpand(expandedKeys) {
    expandedKeys = expandedKeys.map((i) => parseInt(i));
    for (let root of this.keyTree) {
      this.setExpanded(root, new Set(expandedKeys));
    }
    this.props.onChange(this.keyTree.map((t) => copyTree(t, false)), false);
  }

  /**
   * Callback from Ant Tree when a node has been loaded
   * @param loadedKeys - The keys of the tree that are expanded
   */
  onLoadKeys(loadedKeys) {
    loadedKeys = loadedKeys.map((i) => parseInt(i));
    for (let root of this.keyTree) {
      this.setLoaded(root, new Set(loadedKeys));
    }
    this.props.onChange(this.keyTree.map((t) => copyTree(t, false)), false);
  }

  /**
   * Fills in an array of keys corresponding to the checked nodes of the tree, and also gives each
   * node of the tree a unique key
   * @param node - The root of the tree
   * @param checkedKeys - The array of checked keys to be filled
   * @param expandedKeys - The array of expanded keys to be filled
   * @param loadedKeys - The array of loaded keys to be filled
   * @param key - The first key number to be used in the tree
   */
  fillKeys(node, checkedKeys, expandedKeys, loadedKeys, key) {
    node.key = key++;
    if (node.expanded) {
      expandedKeys.push(node.key);
    }
    if (node.loaded) {
      loadedKeys.push(node.key);
    }
    if (node.checked) {
      checkedKeys.push(node.key);
      for (let child of node.children) {
        [, key] = this.fillKeys(child, [], expandedKeys, loadedKeys, key);
      }
    } else {
      for (let child of node.children) {
        [, key] = this.fillKeys(child, checkedKeys, expandedKeys, loadedKeys, key);
      }
    }
    return [node, key];
  }

  /**
   * Renders the tree nodes
   * @param data - The tree to render
   */
  renderTreeNodes(tree) {
    return tree.map((item) => {
      if (item.children.length) {
        return (
          <AntTreeNode title={item.name} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </AntTreeNode>
        );
      }
      return (
        <AntTreeNode
          isLeaf={item.type == this.props.leaf}
          title={item.name}
          key={item.key}
          dataRef={item}
        />
      );
    });
  }

  /**
   * Called the first time a node is clicked.
   * @param antNode - The ant representation of the clicked node
   */
  onLoad(loadedKeys, struct) {
    this.onLoadKeys(loadedKeys);
  }

  /**
   * Called the first time a node is clicked.
   * @param antNode - The ant representation of the clicked node
   */
  onLoadData(antNode) {
    let node = antNode.props.dataRef;

    let typ = {
      Task: 'tasks',
      TaskCategory: 'taskcategories',
      Estate: 'estates',
      EstateGroup: 'estategroups',
      Organisation: 'organisation'
    }[node.type];

    if (node.children.length) {
      return new Promise((resolve) => resolve());
    }
    let url = `http://estatelogs.test:1234/local-api/3/${typ}/${node.uuid}/children`;
    let okCallback = (ret) =>
      ret
        .json()
        .then((json) => {
          antNode.props.dataRef.children = json.data.map((c) => ({
            ...c,
            children: [],
            checked: c.checked
          }));
          this.props.onChange(this.keyTree.map((t) => copyTree(t, true)));
        })
        .catch((j) => console.log('bad', j));
    let badCallback = (j) => console.log('bad', j);
    let errorCallback = (_) => console.log('err');
    return fetchRequest({ url, token: this.props.token, okCallback, badCallback, errorCallback });
  }

  /**
   * Renders the tree
   */
  render() {
    // Fill in the tree with ids that Ant needs
    let checkedKeys = []; // Ids of checked nodes
    let expandedKeys = [];
    let loadedKeys = [];
    this.keyTree = []; // Id'd tree that we keep so we can return an actual tree to callback
    let key = 0;
    for (let root of this.props.tree) {
      let newRoot = copyTree(root, false);
      [, key] = this.fillKeys(newRoot, checkedKeys, expandedKeys, loadedKeys, key);
      this.keyTree.push(newRoot);
    }

    const { t } = this.props;
    const T = (...k) => capitalize(t(...k));

    return (
      <div>
        <div />
        <div>
          {!this.props.tree.length ? (
            <div style={{ marginLeft: '0.5em', color: '#1890ff' }}>No permitted objects.</div>
          ) : (
            <div>
              <div className='overrideexpandbutton'>
                <AntTree
                  checkable
                  loadedKeys={loadedKeys.map((i) => '' + i)}
                  loadData={this.onLoadData.bind(this)}
                  onLoad={this.onLoad.bind(this)}
                  onExpand={this.onExpand.bind(this)}
                  onCheck={this.onCheck.bind(this)}
                  expandedKeys={expandedKeys.map((i) => '' + i)}
                  checkedKeys={checkedKeys}
                  selectable={false}
                >
                  {this.renderTreeNodes(this.keyTree)}
                </AntTree>
              </div>
              {this.props.helpText || (
                <div className='tree-helptext-outer'>
                  <div>{T('tree-explanation-1')}</div>
                  <Icon
                    type='caret-right'
                    theme='filled'
                    style={{ color: '#1890ff', paddingLeft: '0.2em', paddingRight: '0.2em' }}
                  />
                  <div>{t('tree-explanation-2')}</div>
                  <div
                    className={'ant-tree-checkbox-inner'}
                    style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
                  />
                  <div>{t('tree-explanation-3')}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('reportGenerator')(OrganisationTree);
